import axios from "axios";
import moment from "moment";

const url = new URL(window.location.href);

export interface Action {
  title: string;
}

let refreshToken: any = null;

class AuthService {
  getParameters() {
    let paramString = window.location.href.split("?")[2];
    let params_arr = paramString.split("&");
    for (let i = 0; i < params_arr.length; i++) {
      let pair = params_arr[i].split("=");
      return pair[1];
    }
  }

  getApplicationForm() {
    fetch(
      `${process.env.REACT_APP_SERVER_NAME}/api/Auth/GetTokenByTaxId/117041076`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log("Form Token: " + data.access_token);
        window.localStorage.setItem("form_token", data.access_token);
        window.localStorage.setItem("tax_id", "117041076");
        window.localStorage.setItem("company_name", "INVESTLINK CORPORATION S");
        if (data.access_token) {
          (window as Window).location = `/applicationform`;
        } else {
          (window as Window).location = `/applicationform`;
        }
      });
  }

  async login(url1: any) {
    const keycloakConfig = {
      realm: "EATA",
      clientId: "WebPortal",
      wellKnownEndpoint:
        "https://digitaltools-auth.thisisathens.org/auth/realms/EATA/.well-known/openid-configuration",
    };

    let hint;

    if (url.searchParams.has("code")) {
      const searchParams = new URLSearchParams(url.search);

      const response = await axios.get(keycloakConfig.wellKnownEndpoint);
      const tokenEndpoint = response.data.token_endpoint;
      const userinfoEndpoint = response.data.userinfo_endpoint;

      const formData = new URLSearchParams();
      formData.append("grant_type", "password");
      formData.append("client_id", keycloakConfig.clientId);
      formData.append("username", "admin_dev");
      formData.append("password", "Wb3Ti5WX9UHEp5t");
      formData.append("client_secret", "DYZ7LWonGXBfFjtPqnX1iN5M5kfJoTGg");
      formData.append("scope", "openid");

      axios
        .post(tokenEndpoint, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((data) => {
          const currentTime = Date.now(); // Current time in milliseconds
          window.localStorage.setItem("access_token", data.data.access_token);
          window.localStorage.setItem("refresh_token", data.data.refresh_token);
          window.localStorage.setItem("id_token_hint", data.data.id_token);
          window.localStorage.setItem(
            "token_timestamp",
            currentTime.toString()
          );
          this.refreshAccessToken();

          const username = "admin_dev";

          const storedAccessToken = window.localStorage.getItem("access_token");
          const storedRefreshToken =
            window.localStorage.getItem("refresh_token");

          let preferred_username = "";

          axios
            .post(
              userinfoEndpoint,
              {},
              {
                headers: {
                  Authorization: `Bearer ${storedAccessToken}`,
                },
              }
            )
            .then((response) => {
              const data = response.data;
              preferred_username = data.preferred_username;
            })
            .catch((error) => {
              console.error("Error:", error);
            });

          fetch(
            `https://digitaltools-auth.thisisathens.org/auth/admin/realms/EATA/users`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${storedAccessToken}`,
              },
            }
          )
            .then((response) => {
              return response.json();
            })
            .then(async (response) => {
              const provided_afm = await window.localStorage.getItem("afm");

              const user = response.find((row: any) => {
                if (row.attributes && Array.isArray(row.attributes.taxid)) {
                  return row.attributes.taxid[0] === provided_afm;
                }
                return false;
              });

              const userString = JSON.stringify(user);

              await window.localStorage.setItem("user", userString);

              await window.localStorage.setItem("id", user.id);

              const userApps = await axios.get(
                `${process.env.REACT_APP_SERVER_NAME}/api/ApplicationForm/Search?tax_id=${provided_afm}`
              );

              if (userApps.data.length)
                (
                  window as Window
                ).location = `${process.env.REACT_APP_SERVER}/profile`;
              else
                (
                  window as Window
                ).location = `${process.env.REACT_APP_SERVER}/form`;
            });

          this.refreshAccessToken();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      //step 1: call authorize service to get code
      (
        window as Window
      ).location = `https://digitaltools-auth.thisisathens.org/auth/realms/EATA/protocol/openid-connect/auth?client_id=WebPortal&client_secret=&response_type=code&scope=openid&redirect_uri=${process.env.REACT_APP_SERVER}/login&kc_idp_hint=gsis-taxis`;
    }
  }
  logout = async () => {
    const id_token_hint = await window.localStorage.getItem("id_token_hint");
    const role = await window.localStorage.getItem("userType");

    const id = await window.localStorage.getItem("id");
    const access_token = window.localStorage.getItem("access_token");
    await window.localStorage.clear();

    if (role !== "admin") {
      await axios
        .post(
          `https://digitaltools-auth.thisisathens.org/auth/admin/realms/EATA/users/${id}/logout`,
          null,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        )
        .then((response) => {
          console.log(response);
        })
        .catch((error) => {
          console.error("Error on logout:", error);
        });

      (
        window as Window
      ).location = `https://www1.gsis.gr/oauth2server/logout/TPQIDO19419`;
    } else
      (window as Window).location = `${process.env.REACT_APP_SERVER}/loginuser`;
  };

  async getCurrentUser() {
    const userStr = await window.localStorage.getItem("user");

    if (userStr) return userStr;
    else {
      return null;
    }
  }
  navigateProfile() {
    (window as Window).location = `${process.env.REACT_APP_SERVER}/profile`;
  }

  async getUserProfile() {
    if (
      !window.localStorage.getItem("userData") &&
      window.location.pathname == "/volunteer-area"
    ) {
      this.login(window.location.href);
    }
    if (moment().isAfter(window.localStorage.getItem("expDate"))) {
      refreshToken(window.location.href);
    }
    await fetch(`${process.env.REACT_APP_SERVER}/api/el/users/profile`, {
      method: "GET",
      headers: new Headers({
        Accept: "application/json",
        Origin: "",
        Authorization: `Bearer ${window.localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        window.localStorage.setItem("userData", JSON.stringify(data)!);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  ToggleParticipation = async (id: any) => {
    let actionId = id;
    console.log("TOGGLE PARTICIPATION - id: ", id);
    if (typeof id == "number") {
      actionId = id;
    } else if (typeof id == "object") {
      actionId = id.props;
    } else if (typeof id == "string") {
      actionId = id;
    }

    window.localStorage.getItem("access_token");

    await fetch(
      `${process.env.REACT_APP_SERVER}/api/volunteering-actions/${actionId}/toggle-participation`,
      {
        method: "POST",
        headers: new Headers({
          Accept: "application/json",
          Origin: "",
          Authorization: `Bearer ${window.localStorage.getItem(
            "access_token"
          )}`,
          "Content-Type": "application/json",
        }),
        body: JSON.stringify({
          grant_type: "client_credentials",
        }),
      }
    )
      .then((response) => {
        return response.text();
      })
      .then((data) => {
        console.log(data);
      });
  };
  getActionNamebyId(id: number) {
    let filteredResult: Action = {
      title: "",
    };
    let myActions: any = [];
    fetch(`${process.env.REACT_APP_SERVER}/api/el/volunteering-actions`, {
      method: "GET",
      mode: "cors",
      headers: { "API-KEY": `${process.env.REACT_APP_API_KEY}` },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        myActions = data.volunteering_actions;
        filteredResult = myActions.find((e: any) => e.id == id);
        console.log(filteredResult);
      });

    return filteredResult.title;
  }

  getToken() {
    return window.localStorage.getItem("a");
  }

  refreshAccessToken = async () => {
    const refreshToken = window.localStorage.getItem("refresh_token");
    const tokenTimestamp = parseInt(
      window.localStorage.getItem("token_timestamp")!,
      10
    );
    const currentTime = Date.now(); // Current time in milliseconds

    const timeElapsed = currentTime - tokenTimestamp;
    const oneMinuteInMillis = 60 * 1000; // 1 minute in milliseconds

    if (timeElapsed >= 4 * 60 * 60 * 1000 + 58 * 60 * 1000) {
      const keycloakConfig = {
        realm: "EATA",
        clientId: "WebPortal",
        wellKnownEndpoint:
          "https://digitaltools-auth.thisisathens.org/auth/realms/EATA/.well-known/openid-configuration",
      };

      const response = await axios.get(keycloakConfig.wellKnownEndpoint);
      const tokenEndpoint = response.data.token_endpoint;

      const formData = new URLSearchParams();
      formData.append("grant_type", "refresh_token");
      formData.append("client_id", keycloakConfig.clientId);
      formData.append("refresh_token", refreshToken!);
      formData.append("client_secret", "DYZ7LWonGXBfFjtPqnX1iN5M5kfJoTGg");

      try {
        const tokenResponse = await axios.post(tokenEndpoint, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        const newAccessToken = tokenResponse.data.access_token;
        const expiresIn = tokenResponse.data.expires_in;

        window.localStorage.setItem("access_token", newAccessToken);
        window.localStorage.setItem("token_timestamp", currentTime.toString());

        // Schedule the next token refresh
        setTimeout(this.refreshAccessToken, oneMinuteInMillis);
      } catch (error) {
        // Handle token refresh failure, e.g., redirect to login or perform other actions
        console.error("Error refreshing access token:", error);
      }
    } else {
      setTimeout(this.refreshAccessToken, oneMinuteInMillis - timeElapsed);
    }
  };

  updateExpiry() {
    var returned_endate: any = moment().add(295, "seconds"); // see the cloning?
    console.log(returned_endate);

    window.localStorage.setItem("expDate", returned_endate);
    if (moment().isAfter(returned_endate)) {
      console.log("to Refresh");
    } else {
      console.log("___");
    }
    // returned_endate.isSame(expected_enddate)  // true
  }

  formatTime = (time: any) => {
    require("moment/locale/el");
    let dateFormatted = moment.unix(time).format("L");
    return dateFormatted;
  };
  formatTimeQuick = (time: any) => {
    require("moment/locale/el");
    let dateFormatted = moment.unix(time).format("L");
    return dateFormatted;
  };
  checkNewest(prop1: any, prop2: any) {
    let date1 = moment(prop1, moment.defaultFormat);
    let date2 = moment(prop2, moment.defaultFormat);
    if (moment(date1).isAfter(date2)) {
      return false;
    } else {
      return true;
    }
  }
}

export default new AuthService();
