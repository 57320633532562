import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./translation";
import { AuthProvider } from "./context/AuthContext";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Home from "./routes/Home/Home";
import Announcements from "./routes/Announcements/Announcements";
import Login from "./routes/Login/Login";
import Dashboard from "./routes/Dashboard/Dashboard";
import Profile from "./routes/Profile/Profile";
import User from "./routes/User/User";
// import ApplicationForm from "./routes/ApplicationForm/ApplicationForm";
import SingleAnnouncement from "./routes/SingleAnnouncement/SingleAnnouncement";
import Application from "./routes/Application/Application";
import { Styles } from "./styles/styles";
import Contact from "./routes/Contact/Contact";
import About from "./routes/About/About";
import LoginUser from "./routes/LoginUser/LoginUser";
import LoginAdmin from "./routes/LoginAdmin/LoginAdmin";
import AddAnnouncement from "./routes/AddAnnouncement/AddAnnouncement";
import EditApplication from "./routes/EditApplication/EditApplication";
import PageNotFound from "./routes/404";
import ForgotPassword from "./routes/Login/ForgotPassword";
import LoginRequestPassword from "./routes/Login/LoginRequestPassword";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <AuthProvider>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Styles />

        <div className="root-container">
          <Header />
          <div className="content-container">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/announcements" element={<Announcements />} />
              <Route
                path="/announcements/:recid"
                element={<SingleAnnouncement />}
              />
              <Route path="/loginadmin" element={<LoginAdmin />} />
              <Route path="/loginuser" element={<LoginUser />} />
              <Route path="/add_announcement" element={<AddAnnouncement />} />
              <Route path="/login" element={<Login />} />
              <Route
                path="/request-password"
                element={<LoginRequestPassword />}
              />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/user" element={<User />} />
              <Route path="/profile" element={<Profile />} />
              {/* <Route path="/applicationform" element={<ApplicationForm />} /> */}
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/form" element={<Application />} />
              <Route path="/about" element={<About />} />
              <Route path="/edit/:id" element={<EditApplication />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </I18nextProvider>
    </BrowserRouter>
  </AuthProvider>
);
